.searchBar {
  position: relative;
}

.focusedSearchBar {
  background: rgb(100, 100, 100, 0.3);
  box-shadow: 0px 0px 0px 1000px rgb(100, 100, 100, 0.3);
  z-index: 1;
}

.searchBarInput {
  width: 100%;
}

.searchBarTitle {
  display: block;
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 60px;
  text-align: center;
}

.searchBarInput {
  width: 100%;
  padding: 17px 15px;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid #ededed;
  border-radius: 6px;
  box-shadow: 0px 4px 28px 6px rgba(38, 38, 38, 0.08);
  color: #000000;
}

.searchBarInput:focus {
  outline: none;
}

.searchBarSuggestions {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
}
