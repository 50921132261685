.searchSuggestions {
  transition: opacity 0.3s ease;
  overflow: hidden;
  margin: 0 -15px -15px -15px;
  padding: 0 15px 15px 15px;
}

.searchSuggestionsVisible {
  opacity: 1;
}

.searchSuggestionsInvisible {
  opacity: 0;
}

.searchSuggestions::before {
  content: "";
  display: block;
  transition: margin-top 0.3s 0.1s ease;
}

.searchSuggestionsVisible::before {
  margin-top: 0;
}

.searchSuggestionsInvisible::before {
  margin-top: -300px;
}

.searchSuggestionsTopLine {
  padding: 12px 12px 6px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  line-height: 18px;
  color: var(--hcColor-secondaryInverted55);
}

.searchSuggestionsTopLineTitle {
  font-weight: bold;
}

.searchSuggestionsTopLineButton {
  padding: 0;
  border: none;
  background: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
}

.searchSuggestionsTopLineButton:hover {
  text-decoration: underline;
}

.searchSuggestionsList {
  padding: 8px 0;
  background: #fff;
  box-shadow: 0px 4px 14px 2px rgba(38, 38, 38, 0.08);
  border-radius: 6px;
  color: #000;
  max-height: 270px;
  overflow: auto;
  box-sizing: border-box;
}

.searchSuggestionsEmptyMessage {
  text-align: center;
  color: #999;
}

.searchSuggestionsItem {
  padding: 8px 12px;
  display: flex;
  align-items: center;
}

.searchSuggestionsItem:focus,
.searchSuggestionsItem:hover {
  background: #eee;
  outline: none;
}

.searchSuggestionsItem:first-child {
  margin-top: 0;
}

.searchSuggestionsItemTitle {
  display: block;
  font-size: 13px;
  font-weight: 600;
  color: #262626;
}

.searchSuggestionsItemSubtitle {
  display: block;
  font-size: 13px;
  font-weight: 400;
  color: rgba(38, 38, 38, 0.64);
}

.searchSuggestionsItemPublishDate {
  margin-left: auto;
  font-size: 13px;
  font-weight: 400;
  color: rgba(38, 38, 38, 0.64);
}
