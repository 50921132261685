.categoriesList {
  list-style: none;
  margin: 0;
  padding: 0;
}

.categoriesListItem {
  vertical-align: top;
  box-sizing: border-box;
  margin-bottom: 16px;
}

.categoriesListItemGrid {
  display: inline-block;
}

@media (min-width: 1001px) {
  .categoriesListItemGrid {
    width: 33.333%;
  }

  .categoriesListItemGrid:nth-child(3n + 1) {
    padding-right: 10.6px;
  }

  .categoriesListItemGrid:nth-child(3n + 2) {
    padding-left: 5.3px;
    padding-right: 5.3px;
  }

  .categoriesListItemGrid:nth-child(3n + 3) {
    padding-left: 10.6px;
  }
}

@media (min-width: 761px) and (max-width: 1000px) {
  .categoriesListItemGrid {
    width: 50%;
  }

  .categoriesListItemGrid:nth-child(odd) {
    padding-right: 8px;
  }

  .categoriesListItemGrid:nth-child(even) {
    padding-left: 8px;
  }
}

@media (max-width: 760px) {
  .categoriesListItemGrid {
    width: 100%;
  }
}
