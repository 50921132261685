.articlesList {
  list-style: none;
  margin: 0;
  padding: 0;
}

.articlesListExpanded .articlesListItem {
  width: 100%;
}

.articlesListExpanded .articlesListItem:nth-child(odd) {
  padding-right: 0;
}

.articlesListExpanded .articlesListItem:nth-child(even) {
  padding-left: 0;
}

.articlesListItem {
  vertical-align: middle;
  display: inline-block;
  margin-bottom: 16px;
  width: 100%;
  box-sizing: border-box;
}

@media (min-width: 860px) {
  .articlesListItem {
    width: 50%;
  }

  .articlesListItem:nth-child(odd) {
    padding-right: 8px;
  }

  .articlesListItem:nth-child(even) {
    padding-left: 8px;
  }
}
