.container {
  padding: 0;
  overflow: auto;
  min-width: 450px;
  min-height: 100vh;
  background: var(--hcColor-secondary);
  display: flex;
  flex-flow: column;
}

.content {
  width: 100%;
  max-width: 1020px;
  margin: 0 auto;
  padding: 0 30px;
}

.header {
  background: var(--hcColor-primary);
  background-size: cover;
  background-position: center;
  color: var(--hcColor-primaryInverted);
  padding: 40px 0;
}

.headerContent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  gap: 14px 20px;
}

.headerTitleLink {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
  font-size: 16px;
  margin: 0 auto 0 0;
}

.headerTitleLinkLogoContainer {
  background-position: center;
  background-size: contain;
}

.headerTitleLinkLogoContainer .headerLogo {
  opacity: 0;
}

.headerLogo {
  max-height: 40px;
  width: auto;
  height: auto;
}

.headerTitle {
  display: inline-block;
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
  color: var(--hcColor-primaryInverted);
}

.headerItem {
  font-size: 13px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
}

.headerLinkIcon {
  font-size: 140%;
  vertical-align: top;
  line-height: 90%;
  margin-left: 0.3em;
}

.headerSearch {
  width: 100%;
}

.headerSearchBar {
  display: block;
  margin: 30px auto -70px;
  max-width: 600px;
}

.main {
  padding: 6em 0 4em;
}

.withoutTopPadding {
  padding-top: 0;
}

@media (min-width: 880px) {
  .layoutColumns::after {
    content: "";
    display: table;
    clear: both;
  }

  .layoutContentColumn {
    padding-right: 340px;
  }

  .layoutSidebarColumn {
    width: 272px;
    float: right;
  }

  .layoutSidebarColumn > * {
    max-width: 272px;
  }
}

@media (max-width: 879px) {
  .layoutColumns {
    display: flex;
    flex-direction: column-reverse;
    gap: 40px;
  }
}

.footer {
  color: var(--color-grey7);
  margin-top: auto;
  margin-bottom: 50px;
  font-size: 13px;
  line-height: 22px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 40px;
}

.footerLinks {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 8px 16px;
}

.footerLink {
  display: flex;
  align-items: center;
  column-gap: 4px;
}

.footerLinkIcon {
  vertical-align: top;
  width: 22px;
  height: 22px;
}

.footerPowered {
  white-space: nowrap;
}

.footerLinks + .footerPowered {
  margin-left: auto;
}

@media print {
  .headerSearch {
    display: none;
  }

  .headerItem {
    display: none;
  }

  .main {
    padding: 2em 0 0 0;
  }

  .footer {
    display: none;
  }

  .container {
    background: none;
  }

  .layoutSidebarColumn {
    display: none;
  }
}
