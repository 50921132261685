.container {
  padding: 0 2rem;
}

.main {
  min-height: 100vh;
  padding: 4rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container a {
  color: #0070f3;
  text-decoration: none;
}

.container a:hover,
.container a:focus,
.container a:active {
  text-decoration: underline;
}

.title {
  margin: 0;
  line-height: 1.15;
  font-size: 3rem;
}

.title {
  text-align: center;
}

.errorMessage {
  color: red;
}

.sectionTile {
  display: flex;
  align-items: center;
  border: 1px solid var(--hcColor-secondaryInverted30);
  border-radius: 5px;
  margin: 2em 0;
  padding: 2em;
  color: #333;
  box-shadow: 0px 1px 14px -6px grey;
  transform: scale(1);
  transition: box-shadow 0.5s ease, transform 0.5s ease;
}

.sectionTile:hover {
  transform: scale(1.005);
  box-shadow: 0px 1px 14px -2px grey;
}

.emptyListMessage {
  font-size: 3em;
  text-align: center;
  color: var(--hcColor-secondaryInverted30);
}

.wideInput {
  width: 100%;
}

.table {
  width: 100%;
  border-spacing: 0;
}

.table th,
.table td {
  padding: 0.8em 0.2em;
}

.table th {
  text-align: left;
}

.table td {
  border-top: 1px solid lightgray;
}

.breadcrumbs {
  font-size: 14px;
  margin-bottom: 32px;
}

.breadcrumbs,
.breadcrumbs a,
.breadcrumbs h1 {
  color: var(--hcColor-secondaryInverted55);
}

.breadcrumbs a {
  text-decoration: none;
}

.breadcrumbs a:hover {
  text-decoration: underline;
}

.breadcrumbs > *:last-child {
  color: var(--hcColor-secondaryInverted85);
  font-weight: 500;
}

.breadcrumbs h1 {
  display: inline-block;
  font-size: inherit;
  font-weight: inherit;
}

.breadcrumbsArrow {
  margin: 0 8px;
}

.categoryPageTile {
  margin-bottom: 32px;
}

.categoryPageTitle {
  font-size: 16px;
  font-weight: 600;
  color: var(--hcColor-secondaryInverted);
  margin: 8px 0 16px;
}

.categoryInfo {
  margin-bottom: 28px;
}

.categoryInfoIcon {
  font-size: 20px;
  line-height: 1;
  margin-bottom: 20px;
}

.categoryInfoTitle {
  color: var(--hcColor-secondaryInverted);
  font-size: 24px;
  font-weight: 600;
  line-height: 1;
}

.categoryInfoDescription {
  color: var(--hcColor-secondaryInverted55);
  font-size: 16px;
  line-height: 1;
  margin-top: 8px;
}

.articlePageContent,
.articlePageSidebar {
  padding: 0 0 40px;
}

.articlePageTitle {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  color: var(--hcColor-secondaryInverted);
}

.articlePageUpdatedDate {
  color: var(--hcColor-secondaryInverted55);
  font-size: 14px;
  margin: 2px 0;
}

.companyPageTitle {
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 600;
  color: var(--hcColor-secondaryInverted);
}

.companyPageContent:not(:last-child) {
  margin-bottom: 64px;
}

.searchPageTitle {
  color: var(--hcColor-secondaryInverted85);
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

.searchPageResultsCounter {
  color: var(--hcColor-secondaryInverted55);
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.searchPageResults {
  margin-top: 32px;
}

.searchPageFilterTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--hcColor-secondaryInverted85);
  margin-bottom: 12px;
}

.searchPageFilterButton {
  border: 0;
  background: transparent;
  font-weight: 600;
  font-size: 13px;
  line-height: 21px;
  color: var(--hcColor-secondaryInverted85);
  padding: 12px 14px;
  display: block;
  cursor: pointer;
  width: 100%;
  text-align: left;
  text-decoration: none;
}

.searchPageFilterButtonActive,
.searchPageFilterButton:hover {
  background: #fff;
  color: #262626;
}

@media (max-width: 899px) {
  .smallInfopane {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }

  .searchPageFilterTitle,
  .searchPageFilterButton {
    display: inline-block;
    width: auto;
    margin: 0;
  }

  .searchPageFilterButton {
    padding: 6px 7px;
    border-radius: 4px;
    margin-left: 0.3em;
  }
}

@media (min-width: 900px) {
  .smallInfopane {
    float: right;
    width: 240px;
  }

  .smallInfopane ~ * {
    margin-right: 320px;
  }
}

@media (min-width: 1000px) {
  .smallInfopane {
    width: 270px;
  }

  .smallInfopane ~ * {
    margin-right: 380px;
  }
}

.categoryPageSubcategories + .categoryPageArticles {
  margin-top: 48px;
}

.categoryPageArticlesTitle {
  color: var(--hcColor-secondaryInverted);
  font-size: 16px;
  line-height: 1;
  font-weight: 600;
  margin-bottom: 24px;
}

@media print {
  .articlePageBreadcrumbs {
    display: none;
  }

  .articlePageArticle {
    padding-right: 0;
    padding-top: 0;
  }
}
